import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        error: '#FF7043',
        primary: "#07617b",
        sideDrawer: "#ffffff",
        snackBar: "#2d333b",
        bgLight: "#f5f5f5",
        tableText: "#000000"
        
      },
      dark: {
        error: '#FF7043',
        primary: "#0098c4",
        sideDrawer: "#272727",
        secondary: "#6d838a",
        snackBar: "#22272e",
        bgLight: "#282d33",
        actionList: "#2d333b",
         tableText: "#f5f5f5"
      },
    },
    dark: true,
  },
});
