export default {
  state: {
    data: false,
  },
  getters: {
    //
  },
  actions: {
    clearRunningLog(context) {
      context.commit("saveRunningLog", false);
    },
  },
  mutations: {
    saveRunningLog(state, data) {
      return (state.data = data);
    },
  },
};
