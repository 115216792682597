export default {
  state: {
    data: false,
  },
  getters: {
    //
  },
  actions: {
    endSync(context) {
      context.commit("startSync", false);
    },
  },
  mutations: {
    startSync(state, data) {
      return (state.data = data);
    },
  },
};
